<template>
  <section
    class="custom-dashboard-table"
    v-loading="loading"
    element-loading-text="Gathering Data..."
  >
    <el-row :gutter="12">
      <el-col :span="21" style="padding: 9px">
        <b>{{ componentData.component_name }}</b>
      </el-col>
      <!-- Expanding, Edit and Delete Component -->
      <el-col :span="1">
        <el-dropdown
          trigger="click"
          v-if="!hide_options && isExpandTable === false"
        >
          <el-button
            icon="el-icon-more"
            type="text"
            class="icon-style mr-1"
          ></el-button>

          <el-dropdown-menu slot="dropdown">
            <a @click="editDialogBox">
              <el-dropdown-item>Edit</el-dropdown-item>
            </a>
            <a @click="deleteComponent">
              <el-dropdown-item>Delete</el-dropdown-item>
            </a>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown
          trigger="click"
          v-if="!hide_options && isExpandTable === true"
        >
          <el-button icon="el-icon-more" type="text"></el-button>

          <el-dropdown-menu slot="dropdown">
            <a @click="editDialogBox">
              <el-dropdown-item>Edit</el-dropdown-item>
            </a>
            <a @click="deleteComponent">
              <el-dropdown-item>Delete</el-dropdown-item>
            </a>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-col :span="1" class="icon-style">
        <el-button
          icon="el-icon-full-screen"
          type="text"
          v-if="isExpandTable === false"
          @click="expendTable"
        ></el-button>
        <el-button
          v-if="isExpandTable === true"
          icon="el-icon-full-screen"
          type="text"
          @click="expendTable"
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="12">
      <div
        style="display: flex"
        v-if="
          (quick_filters && quick_filters.length) ||
          componentData.allowOtherShowData
        "
      >
        <div v-if="componentData.allowOtherShowData">
          <el-select
            placeholder="Select users"
            v-model="selectedApplicationUser"
            size="mini"
            @change="getMountedData({ date: new Date(), view: 'Month' })"
          >
            <el-option :value="'ALL'" label="All"> </el-option>
            <el-option
              :value="
                currentActiveWorkspace && currentActiveWorkspace.account_data_id
                  ? currentActiveWorkspace.account_data_id
                  : ''
              "
              :label="
                getAuthenticatedUser.first_name +
                ' ' +
                getAuthenticatedUser.last_name
              "
            >
            </el-option>
          </el-select>
        </div>
        <div v-for="(qFilter, i) in quick_filters" :key="i + '_filters'">
          <QuickEntityFilters
            class="ml-1"
            :key="componentData.entity_id + '_' + index + '_table_filters'"
            :filterFields="qFilter.filters"
            :allFields="qFilter.fields"
            :selectedFileds="selectedFileds"
            @quick-filter-updated="applyQuickFilter"
            :fromCD="true"
          ></QuickEntityFilters>
        </div>
      </div>
    </el-row>
    <div>
      <template>
        <div class="demo-app">
          <div
            class="demo-app-main"
            v-if="!loading"
            style="height: 100%; width: 100%"
          >
            <!-- VueFullCalendar -->
            <!-- <FullCalendar
              
            </FullCalendar> -->
            <CustomEntityCalendarWidgets
              :calenderData="calenderData"
              :selectedEntities="selectedCalendarData"
              :customDashboard="customDashboard"
              :parentDate="currentDate"
              :parentView="currentView"
              :isFromApplicationuser="isFromApplicationuser"
              @newEntityData="getMountedData"
            >
            </CustomEntityCalendarWidgets>
          </div>
        </div>
      </template>
    </div>
    <!-- Editing the component that we have imported -->
    <dialog-component
      :title="'Edit Component'"
      :visible="centerDialogVisible"
      :containerWidth="'60%'"
      :containerMinHeight="'20%'"
      @before-close="CenterDialogVisible"
    >
      <div
        v-if="addStatsData && isPopupOpen"
        class="main-form mt-1 scrollbar-content"
      >
        <!-- <label>Menu Icon</label>
        <el-button
          type="text"
          class="text-center"
          @click="iconPickerdialogVisible = true"
        >
          <img
            :src="require('@/assets/img/icons/menu/' + addStatsData.icon)"
            alt="menu"
            width="35"
            v-if="addStatsData.icon"
          />
          <img
            src="@/assets/img/icons/menu/dashboard.svg"
            alt="menu"
            width="35"
            v-else
          />
        </el-button> -->
        <label
          >Component name
          <span class="text-danger">*</span>
        </label>
        <el-input
          v-model="addStatsData.component_name"
          placeholder="Enter component name"
          class="mb-1"
        ></el-input>
        <label>Entities</label>
        <el-select
          v-model="addStatsData.selectedCalendarEntities"
          no-data-text="No Entities available"
          filterable
          default-first-option
          @change="getEntityDateFields"
          class="mb-1"
          multiple
        >
          <el-option
            v-for="(entity, index) of getAllEntitiesData"
            :value="entity._id"
            :key="index"
            :label="entity.name"
            :disabled="checkSelectedEntity(entity)"
          >
            <span style="float: left">{{ entity.name }}</span>
          </el-option>
          <el-option
            v-for="(op, i) in getNestedRelationshipEntities"
            :key="op.key + i"
            :value="op.key"
            :label="op.name"
          ></el-option>
        </el-select>
        <div>
          <!-- <el-link
            icon="el-icon-circle-plus"
            type="primary"
            class="float-right"
            @click="addNewRow"
            :underline="false"
            >Add</el-link
          > -->
          <el-table :data="addStatsData.calenderDateField" border="">
            <el-table-column prop="Contact Type" label="Fields">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.field"
                  filterable
                  clearable
                  default-first-option
                  class="mb-1"
                  @change="getEntityFilters"
                >
                  <el-option
                    v-for="(field, index) of getTemplateDateFields"
                    :value="field.key"
                    :key="field.key + index"
                    :label="`${field.entity_name} - ${field.template_name} - ${field.label} `"
                    :disabled="
                      (addStatsData && addStatsData.calenderDateField
                        ? addStatsData.calenderDateField
                        : []
                      ).findIndex((ex) => ex.field == field.key) != -1
                    "
                  >
                    <span
                      >{{ field.entity_name }} - {{ field.template_name }} -
                      {{ field.label }}</span
                    >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="Custom Message"
              label="Custom Message"
              width="250"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.field &&
                    scope.row.field.includes('#') &&
                    scope.row.field.split('#')[0]
                  "
                >
                  <!-- CustomEntityMention to mention the fields in the templates -->
                  <CustomEntityMention
                    :entity_id="scope.row.field.split('#')[0]"
                    :data="scope.row"
                    :isFromApplicationuser="isFromApplicationuser"
                  >
                  </CustomEntityMention>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="Filter" label="Filter">
              <template slot-scope="scope">
                <el-select
                  clearable
                  v-model="scope.row.filter"
                  placeholder="Select filter"
                  v-if="
                    scope.row.field &&
                    scope.row.field.includes('#') &&
                    scope.row.field.split('#')[0]
                  "
                >
                  <el-option
                    v-for="(op, i) of getCurrenEntityFilter(
                      scope.row.field.split('#')[0]
                    )"
                    :key="i + op._id"
                    :value="op._id"
                    :label="op.filter_name"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="show every year" label="Show every year">
              <template slot-scope="scope">
                <div class="d-flex">
                  <div>
                    <el-checkbox
                      type="textarea"
                      v-model="scope.row.show_every_year"
                      class="mr-2"
                    >
                    </el-checkbox>

                    <el-link
                      class="mr-2"
                      @click.native.prevent="
                        deleteRow(scope.$index, addStatsData.calenderDateField)
                      "
                      type="danger"
                      icon="el-icon-delete"
                    >
                    </el-link>
                  </div>
                  <div>
                    <el-color-picker
                      title="colorPicker"
                      size="mini"
                      v-model="scope.row.color"
                    ></el-color-picker>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Show background color">
              <template slot-scope="scope">
                <div>
                  <el-checkbox
                    :disabled="checkBackgroundColor(scope.$index)"
                    type="textarea"
                    v-model="scope.row.applyBackground"
                  ></el-checkbox>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="calendar-header">
        <div class="child-a mt-1">
          <p><b>HINT:</b>Type @ to insert entity field</p>
        </div>
        <div class="child-a mt-2 mr-2">
          <el-link
            icon="el-icon-circle-plus"
            type="primary"
            class="float-right"
            @click="addNewRow"
            :underline="false"
            >Add</el-link
          >
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancel</el-button>
        <el-button type="primary" :disabled="checkSave" @click="saveEditData"
          >Confirm</el-button
        >
      </span>
    </dialog-component>
    <el-dialog
      title="Select Menu Icon1"
      :visible.sync="iconPickerdialogVisible"
      top="25px"
      width="1000px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="iconslistScrollable">
        <el-row :gutter="10">
          <el-col
            :xs="4"
            :sm="3"
            :md="2"
            :lg="2"
            :xl="1"
            v-for="(icon, index) in menuIcons"
            :key="index"
            class="border-radius"
            style="cursor: pointer"
          >
            <div
              class="grid-content ep-bg-purple"
              v-on:click="setIconPath(icon)"
            >
              <el-tooltip
                effect="dark"
                :content="icon.name"
                placement="top-start"
              >
                <img
                  :src="require('@/assets/img/icons/menu/' + icon.url)"
                  :alt="icon.name"
                  width="25"
                />
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </section>
</template>

<script>
var menuIconsData = require("@/assets/data/menu_icons.json");
import { mapGetters } from "vuex";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import moment from "moment";
import { postAPICall } from "@/helpers/httpHelper";
import { fetchAllEntityFiltersByEntity, fetchEntitiesAndFilters } from "@/repo/filtersRepo";

// const worker = new Worker("worker.js");

export default {
  mixins: [CustomDashboardConfig, NavigationHelper],
  props: {
    component: Object,
    index: Number,
    hide_options: Boolean,
    isFromApplicationuser: Boolean,
    customDashboard: Object,
    getNestedRelationshipEntities: Array,
    getAllCompanyTemplatesData: Object,
    getAllEntities: Object,
  },
  components: {
    CustomEntityMention: () =>
      import("@/components/widgets/entityMention/index.vue"),
    CustomEntityCalendarWidgets: () =>
      import("@/components/widgets/EntityCalendarWidgets.vue"),
    QuickEntityFilters: () =>
      import("@/components/widgets/entityQuickFilters.vue"),
  },

  data: function () {
    return {
      selectedCalendarData: [],
      isPopupOpen: false,
      color: "#f754b9",
      centerDialogVisible: false,
      calenderData: [],
      menuIcons: menuIconsData,
      iconPickerdialogVisible: false,
      calendarOptions: {
        timeZone: "UTC",
        themeSystem: "bootstrap",
        allDay: false,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        initialEvents: [],

        eventTextcolor: "#409EFF",
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventsSet: this.handleEvents,
      },
      currentEvents: [],
      componentData: {},
      isExpandTable: false,
      loading: false,
      addStatsData: null,
      getTemplateDateFields: [],
      allEntityFilters: [],
      refresh: false,
      tempView: "",
      isEntityDashboard: false,
      permittedEntities: [],
      permittedRelatedEntities: [],
      currentDate: new Date(),
      currentView: "Month",
      quick_filters: [],
      selectedFileds: {},
      entityFilters: {},
      selectedApplicationUser: "",
      currentActiveWorkspace: null,
    };
  },
  async mounted() {
    // console.log("getNestedRelationshipENtit",this.getNestedRelationshipEntities)
    this.componentData = JSON.parse(JSON.stringify(this.component));
    let activeWorkspace = this.getActiveContactType?.contact_type?._id;
    this.currentActiveWorkspace = (
      this.getAuthenticatedUser.contact_types || []
    ).find((e) => {
      let id =
        e.contact_type && e.contact_type._id
          ? e.contact_type._id
          : e.contact_type;
      if (id && id == activeWorkspace) {
        return true;
      }
    });
    if (
      this.getAuthenticatedUser &&
      this.getAuthenticatedUser.is_contact &&
      this.getAuthenticatedUser.is_contact == true
    ) {
      this.selectedApplicationUser =
        this.currentActiveWorkspace &&
        this.currentActiveWorkspace.account_data_id
          ? this.currentActiveWorkspace.account_data_id
          : "";
    } else {
      this.selectedApplicationUser = "ALL";
    }
    if (this.$route.name == "entity-custom-dashboard-config") {
      this.isEntityDashboard = true;
    }
    this.getMountedData({ date: new Date(), view: "Month" });
  },
  computed: {
    getDataCan() {
      return this.calenderData;
    },
    getIsExpandTable() {
      return this.isExpandTable;
    },
    ...mapGetters("entities", ["getAllCalendarsData"]),
    // ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    // ...mapGetters("filters", ["getAllFilters"]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveContactType"]),
    ...mapGetters("auth", ["getGlobalFiltervalue"]),
    getAllEntitiesData() {
      if (this.getAllEntities && this.getAllEntities?.data) {
        if (this.isEntityDashboard) {
          return (this.getAllEntities.data || []).filter(
            (e) => this.permittedEntities.indexOf(e._id) != -1
          );
        }
        return this.getAllEntities.data;
      }
      return [];
    },
    checkSave() {
      if (this.addStatsData) {
        if (!this.addStatsData.component_name) {
          return true;
        }
        if (this.addStatsData.component_name.length < 0) {
          return true;
        }
        if (this.addStatsData.calenderDateField.length) {
          let empty = this.addStatsData.calenderDateField.find(
            (e) =>
              !e.field ||
              !e.custom_message ||
              (typeof e.custom_message == "object" &&
                Object.keys(e.custom_message).length == 0) ||
              !e?.custom_message?.content[0]?.content
          );
          if (empty) {
            return true;
          }
          if (
            !this.addStatsData.selectedCalendarEntities ||
            !this.addStatsData.selectedCalendarEntities.length
          ) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    },
    selectedEntities() {
      if (this.getAllEntitiesData && this.getAllEntitiesData.length) {
        let fields = (this.componentData.calenderDateField || []).flatMap(
          (e) => e.field
        );

        return this.getAllEntitiesData
          .filter((e) => {
            if (
              (this.componentData.selectedCalendarEntities || []).indexOf(
                e._id
              ) != -1 &&
              e.primaryFields
            ) {
              let datePrimary = (e.primaryFields || []).find((el) => {
                let needKey = e._id + "#" + el.template_id + "#" + el.key;

                if (fields.indexOf(needKey) != -1) {
                  return true;
                }
              });
              if (datePrimary) {
                return true;
              }
            }
          })
          .map((e) => {
            e.field_ids = fields.filter((t) => {
              if (t && t.includes("#") && t.split("#")[0] == e._id) {
                return true;
              }
            });
            return e;
          });
      }
      return [];
    },
    checkGlobalFilterOnStats() {
      if (this.getCompanyDetails?.global_filters) {
        let filter = this.getCompanyDetails.global_filters.find(
          (e) => e.include_in_topbar
        );
        let entityStats = this.componentData.calenderDateField.flatMap(
          (e) => e.field.split("#")[0]
        );
        if (
          filter?.apply_on &&
          entityStats.find((el) =>
            filter.apply_on.flatMap((e) => e.split("#")[0]).includes(el)
          )
        ) {
          return filter;
        }
        return null;
      }
      return null;
    },
  },
  watch: {
    getGlobalFiltervalue: {
      handler() {
        if (this.checkGlobalFilterOnStats?.apply_on) {
          this.getMountedData({
            date: this.currentDate,
            view: this.currentView,
          });
        }
      },
    },
  },
  methods: {
    checkBackgroundColor(index) {
      if (index > -1 && this.addStatsData?.calenderDateField) {
        let noSelectio = (this.addStatsData.calenderDateField || []).findIndex(
          (e) => e.applyBackground
        );
        if (noSelectio != -1 && noSelectio != index) {
          return true;
        }
      }
      return false;
    },
    applyQuickFilter() {
      this.entityFilters = {};
      for (const property in this.selectedFileds) {
        let selectedEntity = this.quick_filters.find(
          (e) => e.filters && e.filters.indexOf(property) != -1
        );
        if (
          this.selectedFileds[property] &&
          this.selectedFileds[property].length &&
          selectedEntity?.entity_id
        ) {
          let filter = {
            field: property,
            operator: "in",
            value: this.selectedFileds[property],
            value_type: "",
            value_source: "",
            data_type: "STRING",
            query_type: "AND",
            value_field: "",
            validations: {
              data_type: "",
            },
            data_source: "field_option",
          };
          if (this.entityFilters[selectedEntity.entity_id]) {
            this.entityFilters[selectedEntity.entity_id].push(filter);
          } else {
            this.entityFilters[selectedEntity.entity_id] = [filter];
          }
        }
      }
      this.getMountedData({ date: new Date(), view: "Month" });
    },
    getCurrenEntityFilter(id) {
      if (id) {
        let found = this.allEntityFilters.find((e) => e.entity_id == id);
        if (found) {
          return found.filters;
        }
        return [];
      }
      return [];
    },
    getEntityFilters(row) {
      if (
        row &&
        row.includes("#") &&
        row.split("#")[0] &&
        !this.allEntityFilters[row.split("#")[0]]
      ) {
        let index = (
          this.addStatsData && this.addStatsData.calenderDateField
            ? this.addStatsData.calenderDateField
            : []
        ).findIndex((e) => e.field == row);
        if (
          index > -1 &&
          (!this.addStatsData.calenderDateField[index].custom_message ||
            (typeof this.addStatsData.calenderDateField[index].custom_message ==
              "object" &&
              Object.keys(
                this.addStatsData.calenderDateField[index].custom_message
              ).length == 0))
        ) {
          let entity = (this.getAllEntities.data || []).find(
            (e) => e._id == row.split("#")[0]
          );
          if (entity?.primaryFields && entity?.primaryFields[0]) {
            let temp = (this.getAllCompanyTemplatesData?.data || []).find(
              (e) => e._id == entity?.primaryFields[0].template_id
            );
            this.addStatsData.calenderDateField[index]["custom_message"] = {
              type: "doc",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "mention",
                      attrs: {
                        id: temp?.slug + "#" + entity?.primaryFields[0].key,
                        label: null,
                      },
                    },
                    {
                      type: "text",
                      text: " " + row.split("#")[2],
                    },
                  ],
                },
              ],
            };
          }
        }
        let field = this.getTemplateDateFields.find((e) => e.key == row);

        if (field?.inputType) {
          this.addStatsData.calenderDateField[index].inputType =
            field.inputType;
        }
        this.fetchEntityFilters(row.split("#")[0]);
      }
    },
    async fetchEntityFilters(entityId) {
      // await this.$store.dispatch("filters/fetchAllEntityFilters", {
      //   entityId: entityId,
      // });
      const getAllEntityFilters = await fetchAllEntityFiltersByEntity({
        entityId: entityId,
      });
      if (getAllEntityFilters) {
        this.allEntityFilters.push({
          entity_id: entityId,
          filters: getAllEntityFilters,
        });
      }
    },
    async handleEventClick(data) {
      if (
        data &&
        data.id &&
        data.id.includes("#") &&
        data.id.split("#").length > 1
      ) {
        let query = this.getNavigationQuery(this.$route.query);
        let addQuery = {};
        addQuery["dataId"] = data.id.split("#")[1];
        addQuery["fromCustom"] = true;
        this.$router.push({
          path: `/entity_data_view/${data.id.split("#")[0]}`,
          query: { ...query, ...addQuery },
        });
      }
    },
    checkIsDateTime(data) {
      if (
        data &&
        data.id &&
        data.id.includes("#") &&
        data.id.split("#").length > 1
      ) {
        let keyArray = data.id.split("#");
        let field;
        if (keyArray.length == 6) {
          field = keyArray[0] + "#" + keyArray[3] + "#" + keyArray[4];
        } else {
          field =
            keyArray[0] +
            "#" +
            keyArray[keyArray.length - 2] +
            "#" +
            keyArray[keyArray.length - 1];
        }
        let selectedField = this.componentData.calenderDateField.find(
          (e) => e.field == field
        );
        if (selectedField?.inputType == "DATE_TIME") {
          return true;
        }
        return false;
      }
      return false;
    },
    checkSelectedEntity(entity) {
      if (
        this.addStatsData?.calenderDateField &&
        this.addStatsData.calenderDateField.length
      ) {
        let found = this.addStatsData.calenderDateField.find(
          (e) =>
            e.field &&
            e.field.includes("#") &&
            e.field.split("#")[0] == entity._id
        );
        if (found) {
          return true;
        }
      }
      return false;
    },
    async getMountedData({ date, view }) {
      this.loading = true;
      let filters = [],
        entities = [],
        params = {
          entities: [],
        };
      await Promise.all(
        this.componentData.calenderDateField.map(async (e) => {
          this.getEntityFilters(e.field);
          if (
            e &&
            e.field &&
            e.field.includes("#") &&
            e.field.split("#").length > 2
          ) {
            let [entity_id, template_id, key] = e.field.split("#");
            let obj = {};
            if (e?.custom_message?.content[0]?.content) {
              let fields = [];
              (e.custom_message.content[0].content || []).forEach((el) => {
                if (
                  el?.type == "mention" &&
                  el?.attrs?.id &&
                  el.attrs.id.includes("#")
                ) {
                  let [slug, key] = el?.attrs?.id.split("#");
                  let temp = (this.getAllCompanyTemplatesData?.data || []).find(
                    (e) => e.slug == slug
                  );
                  if (temp?._id) {
                    fields.push(temp._id + "#" + key);
                  }
                }
              });
              obj["custom_fields"] = fields;
            }
            if (entity_id && template_id && key) {
              obj["entity_id"] = entity_id;
              obj["filter_id"] = e.filter;
              if (e.filter) {
                filters.push(e.filter);
                entities.push(entity_id);
              }
              obj["fields"] = [template_id + "#" + key];
              if (
                e?.is_relational_field &&
                (!this.componentData.allowOtherShowData ||
                  (this.componentData.allowOtherShowData &&
                    this.selectedApplicationUser != "ALL"))
              ) {
                obj["filters"] = this.applyRelationalFilter([]);
              }
              if (
                e?.is_nested_relational_field &&
                (!this.componentData.allowOtherShowData ||
                  (this.componentData.allowOtherShowData &&
                    this.selectedApplicationUser != "ALL"))
              ) {
                obj["filters"] = await this.applyNestedRelationalFilter([], {
                  selectedEntityNestedRelationalData: e.nested_entity_info,
                  entity_id: entity_id,
                });
              }
              let field = template_id + "#" + key;
              let dateFilter = this.getYearFilter(date.getFullYear(), field);
              if (obj["filters"]) {
                obj["filters"] = [...obj["filters"], ...[dateFilter]];
              } else {
                obj["filters"] = [dateFilter];
              }
              if (this.entityFilters && this.entityFilters[entity_id]) {
                if (obj["filters"]) {
                  obj["filters"] = [
                    ...obj["filters"],
                    ...this.entityFilters[entity_id],
                  ];
                } else {
                  obj["filters"] = this.entityFilters[entity_id];
                }
              }
              let index = params.entities.findIndex(
                (e) => e.entity_id == entity_id
              );
              if (
                this.checkGlobalFilterOnStats?.apply_on &&
                this.checkGlobalFilterOnSingleStat(entity_id)
              ) {
                obj["filters"] = this.addGlobalFilters(
                  obj["filters"] || [],
                  entity_id,
                  this.checkGlobalFilterOnSingleStat(entity_id)
                );
              }
              if (index == -1) {
                params.entities.push(obj);
              } else {
                params.entities[index].fields = [
                  ...params.entities[index].fields,
                  ...obj["fields"],
                ];
                params.entities[index].custom_fields = [
                  ...params.entities[index].custom_fields,
                  ...obj["custom_fields"],
                ];
              }
            }
          }
        })
      );
      if (filters && filters.length) {
        // await this.$store.dispatch("filters/fetchFiltersListByIds", {
        //   filters: filters,
        //   entities: entities,
        // });
        const getAllFilters = await fetchEntitiesAndFilters(
          {
            filters: filters,
            entities: entities,
          }
        );
        if (getAllFilters) {
          this.quick_filters = [];
          let filtersCount = 0;
          if (getAllFilters.filters) {
            getAllFilters.entities.forEach((entity) => {
              if (entity && filtersCount < 5) {
                let filters = getAllFilters.filters.filter(
                  (e) => e.entity_id == entity._id
                );
                if (filters && filters.length) {
                  let selectedEntityFields = [];
                  entity.templates.forEach((e) => {
                    if (e && e.template_id && !e.is_repeatable) {
                      selectedEntityFields = [
                        ...selectedEntityFields,
                        ...this.getFields(e.template_id),
                      ];
                    }
                  });
                  let quickFilters = [];
                  filters.forEach((fl) => {
                    if (fl.quick_filters && quickFilters.length < 5) {
                      quickFilters = [...quickFilters, ...fl.quick_filters];
                      filtersCount = filtersCount + fl.quick_filters.length;
                    }
                  });
                  this.quick_filters.push({
                    fields: selectedEntityFields,
                    filters: quickFilters || [],
                    entity_id: entity._id,
                  });
                }
              }
            });
          }
          // this.getAllFilters.forEach((e) => {
          //   if (e?.quick_filters) {
          //     this.quick_filters = [...this.quick_filters, ...e.quick_filters];
          //   }
          // });
        }
      }
      this.currentDate = date;
      this.currentView = view;
      await Promise.all([
        // this.$store.dispatch("entities/fetchEntities", {
        //   get_all: true,
        // }),
        // this.$store.dispatch("entities/fetchCalendarData", params),
      ]);
      const response = await postAPICall(
        "POST",
        "/getCalender/calender",
        params
      );
      // await this.$store.dispatch("entities/fetchCalendarData", params);
      if (this.getEntityPermission?._id) {
        this.setPermittedEntities();
      }
      if (response?.data) {
        if (window.Worker) {
          try{
            /* This is large computation task so we sending this to web workers */
            const worker = new Worker("worker.js");
            let that = this;
            worker.onmessage = function (event) {
              const result = event.data;
              that.calenderData = result;
              that.loading = false;
              worker.terminate();
            };

            worker.onerror = function () {
              worker.terminate();
              that.calenderData = that.getConfigData(response.data);
              that.loading = false;
            };

            worker.postMessage({
              process_name: "SET_CALENDER",
              data: response.data,
              componentData: this.componentData,
              getAllCompanyTemplatesData: this.getAllCompanyTemplatesData,
            });
          }catch(e){
            this.calenderData = this.getConfigData(response.data);
            this.loading = false;
            console.log("Worker Error", e);
          }
        } else {
          this.calenderData = this.getConfigData(response.data);
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
      this.selectedCalendarData = this.selectedEntities;
    },
    getFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        e.key = data._id + "#" + e.key;
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    getYearFilter(year, field) {
      return {
        field: field,
        operator: "real_time",
        value: new Date(year - 1, 11, 31, 18, 0, 0, 0),
        value_type: "",
        value_source: "",
        data_type: "DATE",
        query_type: "AND",
        value_field: "",
        validations: {
          data_type: "",
        },
        selected_date_picker: "custom_date_range",
        today: new Date(year, 11, 30, 18, 0, 0, 0),
      };
    },
    setPermittedEntities() {
      this.permittedEntities = [];
      this.permittedRelatedEntities = [];

      if (this.getEntityPermission?.other_entities) {
        Object.keys(this.getEntityPermission?.other_entities).forEach((e) => {
          if (
            this.getEntityPermission?.other_entities[e].permissions &&
            this.getEntityPermission?.other_entities[e].permissions.indexOf(
              "VIEW"
            ) != -1
          ) {
            this.permittedEntities.push(e);
          }
        });
      }
      if (this.getEntityPermission?.relational_entities) {
        Object.keys(this.getEntityPermission?.relational_entities).forEach(
          (e) => {
            if (
              this.getEntityPermission?.relational_entities[e].permissions &&
              this.getEntityPermission?.relational_entities[
                e
              ].permissions.indexOf("VIEW") != -1
            ) {
              this.permittedRelatedEntities.push(e);
            }
          }
        );
      }
      if (this.componentData?.selectedCalendarEntities) {
        this.componentData.selectedCalendarEntities.forEach((e) => {
          if (this.permittedEntities.indexOf(e) == -1) {
            this.permittedEntities.push(e);
          }
          if (this.permittedRelatedEntities.indexOf(e) == -1) {
            this.permittedRelatedEntities.push(e);
          }
        });
      }
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    saveEditData() {
      this.componentData = { ...this.addStatsData };
      this.getMountedData({ date: new Date(), view: "Month" });
      this.$emit("tableUpdate", {
        index: this.index,
        data: this.componentData,
        component: "CALENDAR",
      });
      this.centerDialogVisible = false;
      this.$notify.info({
        title: "Info",
        message: "Please Click on Save to make Edit Changes",
      });
    },
    addNewRow() {
      if (this.addStatsData?.calenderDateField) {
        this.addStatsData.calenderDateField.push({
          field: "",
          custom_message: {},
          show_every_year: false,
          inputType: "DATE",
          color: "#f754b9",
          applyBackground: false,
        });
      }
    },
    setIconPath(icon) {
      this.addStatsData.icon = icon.url;
      this.iconPickerdialogVisible = false;
    },
    getEntityDateFields() {
      this.getTemplateDateFields = [];
      if (
        this.addStatsData?.selectedCalendarEntities &&
        this.addStatsData.selectedCalendarEntities.length
      ) {
        this.addStatsData.selectedCalendarEntities.forEach((en) => {
          let entity_id = en.includes("#") ? en.split("#")[1] : en;
          const entity = this.getAllEntitiesData.find(
            (e) => e._id == entity_id
          );
          const templateIds = (
            entity && entity.templates ? entity.templates : []
          ).flatMap((e) => e.template_id);
          const entityTemplates = (
            this.getAllCompanyTemplatesData?.data || []
          ).filter((e) => (templateIds || []).indexOf(e._id) !== -1);
          (entityTemplates || []).forEach((e) => {
            let data = JSON.parse(JSON.stringify(e));
            this.getTemplateDateFields = [
              ...this.getTemplateDateFields,
              ...this.getTemplateFieldsByType(
                data,
                ["DATE", "DATE_TIME", "DATE_TIME_RANGE", "DATE_RANGE"],
                entity
              ),
            ];
          });
        });
      }
    },
    editDialogBox() {
      this.isPopupOpen = true;
      this.disableBackgroundScroll();
      this.addStatsData = JSON.parse(JSON.stringify(this.componentData));
      this.getEntityDateFields();
      this.centerDialogVisible = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      this.enableBackgroundScroll();
    },
    disableBackgroundScroll() {
      document.body.style.overflow = "hidden";
    },
    enableBackgroundScroll() {
      document.body.style.overflow = "";
    },
    CenterDialogVisible() {
      this.centerDialogVisible = false;
    },
    getMapEveryYear(data) {
      let result = [];
      if (data && data.length) {
        data.map((dt) => {
          if (dt.everyYear) {
            let month = new Date(dt.dates).getMonth() + 1;
            let date = new Date(dt.dates).getDate();
            let time = moment(dt.dates).format("HH:mm:ss");
            for (let year = 1900; year <= 2100; year++) {
              let res = JSON.parse(JSON.stringify(dt));
              res.dates = moment(`${year}-${month}-${date} ${time}`).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              res.start = moment(`${year}-${month}-${date} ${time}`).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              res.id = res.id + "#" + year;
              // res.allDay = true;
              result.push(res);
            }
          } else {
            result.push(dt);
          }
        });
      }
      return result;
    },
    getCalendarMessage(data, field) {
      let str = "";
      if (
        data &&
        data.custome_data &&
        field?.custom_message?.content[0]?.content
      ) {
        (field?.custom_message?.content[0]?.content || []).forEach((e) => {
          if (e?.type == "text") {
            str = str + e.text;
          } else if (
            e?.type == "mention" &&
            e?.attrs?.id &&
            e.attrs.id.includes("#")
          ) {
            let [slug, key] = e?.attrs?.id.split("#");
            let temp = (this.getAllCompanyTemplatesData?.data || []).find(
              (e) => e.slug == slug
            );
            if (
              temp?._id &&
              data.custome_data[temp._id] &&
              data.custome_data[temp._id][key]
            ) {
              if (data.custome_data[temp._id][key + "/name"]) {
                str = str + data.custome_data[temp._id][key + "/name"];
              } else if (this.checkDate(data.custome_data[temp._id][key])) {
                str =
                  str +
                  moment(data.custome_data[temp._id][key]).format(
                    "MM-DD-YYYY HH:mm:A"
                  );
              } else {
                str = str + data.custome_data[temp._id][key];
              }
            }
          }
        });
      }
      return str;
    },
    checkDate(string) {
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (dateStamp.test(string)) {
        return true;
      }
      return false;
    },
    getConfigData(getAllCalendarsData) {
      if (getAllCalendarsData.length) {
        let result = [];
        getAllCalendarsData.forEach((el) => {
          if (el?.date && Object.keys(el.date).length > 0) {
            Object.keys(el.date).forEach((temp) => {
              if (temp && el.date[temp] && Object.keys(el.date[temp])) {
                Object.keys(el.date[temp]).forEach((e) => {
                  let id = el.entity_id + "#" + temp + "#" + e;
                  let message = this.componentData.calenderDateField.find(
                    (e) => e.field == id
                  );
                  let customMessage = this.getCalendarMessage(el, message);
                  result.push({
                    customData: customMessage,
                    entity_id: el.entity_id,
                    entity_data_id: el._id,
                    title: customMessage,
                    start: this.getFormatted(
                      message?.inputType,
                      el.date[temp][e]
                    ),
                    everyYear: message?.show_every_year,
                    inputType: message?.inputType,
                    color: message?.color,
                    applyBackground: message?.applyBackground,
                    dates: this.getFormatted(
                      message?.inputType,
                      el.date[temp][e]
                    ),
                  });
                });
              }
            });
          }
        });
        return result;
      }
      return [];
    },
    getFormatted(type, value) {
      if (type == "DATE_TIME_RANGE" || type == "DATE_RANGE") {
        if (typeof value == "object" && value.length) {
          return [
            moment.utc(value[0]).local().format("YYYY-MM-DD HH:mm:ss"),
            moment.utc(value[1]).local().format("YYYY-MM-DD HH:mm:ss"),
          ];
        }
        return [];
      }
      return moment.utc(value).local().format("YYYY-MM-DD HH:mm:ss");
    },
    expendTable() {
      this.$emit("expendTable", this.index);
    },
    expandTablewhenHide() {
      this.isExpandTable = false;
      this.$emit("expendTable", this.index);
    },
    deleteComponent() {
      this.$confirm("Are you sure to delete the Calendar?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-header {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
  width: 100% !important;
}
.demo-app-calendar {
  width: 100% !important;
  font-size: 12px;
}
.custom-dashboard-table {
  border: 1px solid #eaeaea;
  min-height: 310px !important;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  background-color: #ffffff !important;
}
.main-form {
  display: flex;
  flex-direction: column;
}
.scrollbar-content {
  scrollbar-width: thin;
  height: 400px;
  overflow-y: auto;
}
</style>
